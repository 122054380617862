import React from "react"
import styled from "styled-components"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import BlogCard from "@components/blog/card"
import PropTypes from "prop-types"

const BlogTitle = styled.h1`
  font-size: 300%;
  text-align: center;
  margin: 2rem 0 1rem;
  color: #21264f;
`

const BlogSubTitle = styled.h3`
  font-size: 150%;
  text-align: center;
  margin: 0 0 4rem;
  color: #21264f;
`

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 60px;
  margin-bottom: 60px;
`

const CaseStudies = ({ data, location, pageContext }) => {
  return (
    <Layout
      title={"Case Studies"}
      description={"A collection of our most recent Case Studies."}
      location={location}
      canonical={pageContext.slug}
    >
      <BlogTitle>Case Studies</BlogTitle>
      <BlogSubTitle>A collection of our most recent Case Studies.</BlogSubTitle>
      <BlogGrid>
        {data.blogs.edges.map(({ node: blog }) => {
          return (
            <BlogCard
              key={blog.id}
              image={blog.image}
              title={blog.title}
              link={"/" + blog.slug}
              description={blog.summary}
            />
          )
        })}
      </BlogGrid>
    </Layout>
  )
}

CaseStudies.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default CaseStudies

export const pageQuery = graphql`
  query CaseStudiesQuery($relatedBlogs: [String]!) {
    blogs: allBlog(filter: { id: { in: $relatedBlogs } }) {
      edges {
        node {
          title
          summary
          id
          status
          date
          slug
          image {
            id
            title
            cdn
            placeholder
          }
          authors {
            name
          }
        }
      }
    }
  }
`
