import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import Image from "@components/image"

const Card = styled(Link)`
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #ddd;

  :hover {
    h2 {
      color: ${({ theme }) => theme.color.indigo400};
    }
  }
`

const ImageContainer = styled.div`
  max-height: 190px;
  overflow: hidden;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 20px;
  h2 {
    transition: ${({ theme }) => theme.transition.default};
    font-size: 135%;
    line-height: 150%;
    color: #333;
    margin: 0;
  }
  flex: 1;
`

const Subtitle = styled.p`
  margin: auto 0 0 0;
  color: #333333;
`

const Description = styled.p`
  color: #333333;
  margin: 0;
`

const BlogCard = ({ link, title, image, author, date, description }) => {
  return (
    <Card to={link}>
      <ImageContainer>
        {image && <Image height={190} file={image} alt={image.title} />}
      </ImageContainer>
      <TextContainer>
        <h2>{title}</h2>
        {author && date ? (
          <Subtitle>
            By <strong>{author}</strong> &#8212; {date}
          </Subtitle>
        ) : (
          description && <Description>{description}</Description>
        )}
      </TextContainer>
    </Card>
  )
}

BlogCard.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  author: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
}

export default BlogCard
